import { useState, useEffect } from 'react';
import ReactJson from 'react-json-view';
import axios from 'axios';
import { Grid, Typography, TextField, Divider, Checkbox, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import './App.css';

function App() {
  const [data, setData] = useState({});
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [parameter, setParameter] = useState({
    broadcaster: false,
    emotes: false,
    badges: false,
    schedule: false,
    current_stream: false,
    teams: false,
    follower: false,
    follows: false
  });
  
  const handleChecked = (e, value) => {
    var objprop = {...parameter};
    objprop[value] = e.target.checked;
    setParameter(objprop);
  };

  const handleRequestData = () => {
    setLoading(true);
    var objprop = {};
    Object.entries(parameter).map((value, index) => {
      if (value[1]) objprop[value[0]] = true;
    });
    var options = {
      method: 'GET',
      // url: `https://twitch-advanced.p.rapidapi.com/getUserDataByUsername/${username}`,
      url: `https://multiple-rapidapis.herokuapp.com/router/twitchapi/${username}`,
      params: objprop,
      // headers: {
      //   'x-rapidapi-host': 'twitch-advanced.p.rapidapi.com',
      //   'x-rapidapi-key': 'c95c9ad0damsh292b34d95ceef29p10832djsn1aae0dcde50f'
      // }
    };

    axios.request(options).then(function (response) {
      setData(response.data);
      setLoading(false);
    }).catch(function (error) {
      console.error(error);
      setLoading(false);
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      id="maindiv"
    >
      <Grid item>
        <Typography variant="h4">Twitch Advanced API</Typography>
        <Divider />
      </Grid>
      <Grid item>
        <a href="https://rapidapi.com/team-amg-team-amg-default/api/twitch-advanced/" target="_blank">Use this API in your Project</a>
      </Grid>
      <Grid item>
        <LoadingButton loading={loading} variant="outlined" onClick={handleRequestData}>Request Data</LoadingButton>
      </Grid>
      <Grid item>
        <TextField label="Twitch Username" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          {Object.entries(parameter).map((value, index) => (
              <Grid item><Typography key={index} variant="p">{value[0]}:<Checkbox checked={value[1]} onChange={(e) => handleChecked(e, value[0])} /></Typography></Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6">Response:</Typography>
        <ReactJson src={data} />
      </Grid>
    </Grid>
  );
}

export default App;
